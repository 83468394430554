import React, { useState } from 'react';
import { BadgeCheckIcon as BadgeCheckIconSolid } from '@heroicons/react/solid'
import { CollectionIcon as BadgeQueueIconOutline } from '@heroicons/react/outline'
import RecentCertificatesTab from './recentstab.js';
import QueueTab from './queuetab.js';

const tabs = [
    { name: 'Recently generated', icon: BadgeCheckIconSolid },
    { name: 'Queue', icon: BadgeQueueIconOutline },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SearchPage(props) {
    // State
    const [currentTab, setCurrentTab] = useState("Recently generated");

    // Tabs 1
    function selectTab(e, tabName) {
        e.preventDefault();

        setCurrentTab(tabName);
    }

    // Tabs 2
    function dropdownChange(e) {
        setCurrentTab(e.target.value);
    }

    return (
        <div>
            {/* start tab control */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">Select a tab</label>
                    <select id="tabs" name="tabs" onChange={dropdownChange}
                        className="block mt-4 mb-2 w-full focus:ring-isober-500 focus:border-isober-500 border-gray-300 rounded-md"
                        defaultValue={tabs.find((tab) => tab.name).name}>
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a key={tab.name} href="#0" onClick={(e) => selectTab(e, tab.name)}
                                    className={classNames(
                                        tab.name === currentTab
                                            ? 'border-isober-500 text-isober-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.name === currentTab ? 'page' : undefined}>
                                    <tab.icon
                                        className={classNames(
                                            tab.name === currentTab ? 'text-isober-500' : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {/* end tab control */}

            {(currentTab === "Queue") && <QueueTab userinfo={props.userinfo} />}
            {(currentTab === "Recently generated") && <RecentCertificatesTab userinfo={props.userinfo} />}

        </div>
    )
}