import React from 'react';

function ReceivingNotes({ handler, sfv, sft }) {
    const editHandler = (e) => {
        handler("Whee", sfv, sft);
    }

    return (
        <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white px-4 space-y-4 sm:p-4">

                <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Additional Notes</h3>
                    {/*<p className="mt-1 text-sm text-gray-500">Enter notes and additional information in the space below.</p>*/}
                </div>

                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-6">
                        <div className="mt-0">
                            <textarea id="about" onChange={() => editHandler()} name="about" rows="3" className="shadow-sm focus:ring-isober-500 focus:border-isober-500 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder="Enter text here"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReceivingNotes;