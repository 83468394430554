import React, { useState, useCallback } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import CalibrateFragment from './calibrate';
import { formatDate } from '../../Shared/Components/functions.js'

export default function JobCardLocator(props) {
    // HOOKS
    const firestore = useFirestore();

    // STATE 
    const [formLoading, setFormLoading] = useState(false);
    const [jobcardRecord, setJobcardRecord] = useState(undefined);
    const [deviceRecord, setDeviceRecord] = useState(undefined);
    const [jobcardResults, setJobcardResults] = useState(undefined);
    const [querySearchTerm, setQuerySearchTerm] = useState(undefined);

    // FORM

    const initialValues = { query: '' };
    const validationSchema = Yup.object({
        query: Yup.string("Enter search term").min(5).required("Required")
    });

    // any job cards with given serial number
    // FIXME add where state == open etc
    async function jobcardsByDeviceSerial(serialno) {
        const jobcardsCollection = collection(firestore, `jobcards`);
        const activeQuery = query(jobcardsCollection, where('deviceIdx', 'array-contains', serialno));
        const querySnapshot = await getDocs(activeQuery);
        return querySnapshot;
    }

    // fetch a specific job card
    async function jobcardByItsGuid(guid) {
        const docRef = doc(firestore, `jobcards`, guid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        }

        return undefined;
    }

    // get device record by its serial number in a specified job card
    async function jobCardDeviceBySerial(jobcardGuid, serialno) {
        const devicesCollection = collection(firestore, `jobcards/${jobcardGuid}/devices`);
        const deviceQuery = query(devicesCollection, where('serial', '==', serialno), limit(1)); // FIXME OR supported soon with or(where('sensorSerial', '==', serialno))
        const querySnapshot = await getDocs(deviceQuery);

        if (querySnapshot.size === 1) {
            console.log("deviceByDeviceSerial has 1 result");
            const deviceDoc = querySnapshot.docs[0];
            return { id: deviceDoc.id, ...deviceDoc.data() };
        }

        return undefined;
    }

    async function calibrateDevice(selectedJobcardGuid, forDeviceSerial) {
        setFormLoading(true);
        setDeviceRecord(undefined);

        const jobcardRecord = await jobcardByItsGuid(selectedJobcardGuid);
        if (jobcardRecord !== undefined) {
            setJobcardRecord(jobcardRecord);
            const deviceResult = await jobCardDeviceBySerial(jobcardRecord.id, forDeviceSerial);
            if (deviceResult !== undefined) {
                setDeviceRecord(deviceResult);
            }    
        } else {
            // will trigger no such record component to display
            setJobcardResults(undefined);
            setDeviceRecord(undefined);
        }

        setFormLoading(false);
    }

    async function handleSubmit(form) {
        setFormLoading(true);

        // clear state
        setJobcardRecord(undefined);
        setDeviceRecord(undefined);
        setJobcardResults(undefined);
        setQuerySearchTerm(undefined);

        const jobcardResult = await jobcardsByDeviceSerial(form.query.trim());
        if (jobcardResult !== undefined) {
            if (jobcardResult.size === 1) {
                const jobcardDoc = jobcardResult.docs[0];
                const deviceResult = await jobCardDeviceBySerial(jobcardDoc.id, form.query.trim());
                if (deviceResult !== undefined) {
                    setJobcardRecord({ id: jobcardDoc.id, ...jobcardDoc.data() });
                    setDeviceRecord(deviceResult);
                }
            } else if (jobcardResult.size > 1) {
                setJobcardResults(jobcardResult);
                setQuerySearchTerm(form.query.trim());
            } else if (jobcardResult.size === 0) {
                setJobcardResults(jobcardResult); // 
            }
        }

        setFormLoading(false);
    }

    // LIFTING STATE

    function cancelFromCal() {
        setJobcardRecord(undefined);
        setDeviceRecord(undefined);
        setJobcardResults(undefined);
        setQuerySearchTerm(undefined);
    }

    return (
        <div>

            {/* Search form */}
            {(deviceRecord === undefined || jobcardRecord === undefined) && <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Search by identifier</h3>
                    <p className="mt-1 text-sm text-gray-500">Enter a device identifier such as serial number or MAC address e.g. ACAJ1G0002 12345678 EBC0010003</p>
                </div>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="px-6 pb-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6">
                                        <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                            Unit or sensor serial number &nbsp; <ErrorMessage name="query" component="span" className="text-red-700 italic" />
                                        </label>

                                        {/* input with button */}
                                        <div className="mt-1 flex rounded-md shadow-sm">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                <input type="text" name="query" id="query" autoComplete='off' onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 focus:border-isober-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300" placeholder="Enter search term..." />
                                                <div className='absolute top-3 right-2'>
                                                    {formLoading && <img src='/images/loadingtiny.gif' width='16' height='16' alt='' />}
                                                </div>
                                            </div>
                                            <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-isober-500 focus:border-isober-500">
                                                <span>Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div >}

            {/* Include calibration component if needed */}
            {(deviceRecord !== undefined && jobcardRecord !== undefined) && <CalibrateFragment device={deviceRecord} jobcard={jobcardRecord} cancelHandler={cancelFromCal} userinfo={props.userinfo} />}

            {/* Include calibration component if needed */}
            {(querySearchTerm !== undefined && jobcardResults !== undefined && jobcardResults.size > 1) && <SerialChooser queryResult={jobcardResults} choiceHandler={calibrateDevice} forUnitSerial={querySearchTerm} />}

            {/* Include serial not found if needed */}
            {(jobcardResults !== undefined && jobcardResults.size === 0) && <NoSuchSerial />}

            {/* Debug: 
            {(jobcardRecord !== undefined) && <p className='mt-6'>{JSON.stringify(jobcardRecord)}</p>}
            {(deviceRecord !== undefined) && <p className='mt-6'>{JSON.stringify(deviceRecord)}</p>} */}
        </div >
    )
}

function SerialChooser(props) {
    const choiceHandlerCallback = useCallback((selectedJobcardGuid) => {
        props.choiceHandler(selectedJobcardGuid, props.forUnitSerial);
    }, [props]);

    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Search result</h3>
            </div>

            <div className="px-6 pb-6">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <p className="pb-4 block text-sm font-medium text-gray-700">The specified serial number appears on more than one job card:</p>

                        <div className="relative bg-white rounded-md -space-y-px">
                            {props.queryResult.docs.map((record, recordIdx) => (
                                <label key={recordIdx} className="rounded-tl-md rounded-tr-md relative border p-2 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
                                    <div className="my-2 flex items-center text-sm">
                                        <span id="pricing-plans-0-label" className="ml-3">{formatDate(record.data().created.toDate())}</span>
                                    </div>

                                    <div className="my-2 flex items-center text-sm">
                                        <span id="pricing-plans-0-label" className="ml-3">{record.data().clientName}</span>
                                    </div>

                                    <p id="pricing-plans-0-description-1" className="my-2 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">
                                        {record.data().status === 'Open' && <span className="px-2 inline-flex items-center rounded-full text-xs font-medium bg-blue-100 text-blue-800"> Open </span>}

                                        <button onClick={() => choiceHandlerCallback(record.id)} className="ml-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Calibrate
                                    </button>
                                    </p>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function NoSuchSerial() {
    return (
        <div className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Not found</h3>
            </div>

            <div className="px-6 pb-6">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <p className="block text-sm font-medium text-gray-700">The specified serial number does not match any open job cards; please recheck the serial number input or browse the job cards on the Dashboard.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}